<template>
  <b-card>
    <div>
      <validation-observer ref="provider">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="Name" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="role"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Active" label-for="check-button">
                <b-form-checkbox
                  v-model="form.isActive"
                  checked="true"
                  class="custom-control-primary mt-1"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4"> Permissions </b-col>
            <b-col cols="12" md="8">
              <b-form-group label="set:" v-slot="{ ariaDescribedby }">
                <b-form-checkbox
                  v-for="option in optionsPermissions"
                  v-model="form.permissions"
                  :key="option.value"
                  :value="option.value"
                  :aria-describedby="ariaDescribedby"
                  name="permission-group"
                >
                  {{ option.label }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action Buttons -->
          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
            Create role
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BFormCheckbox } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required } from '@validations'
export default {
  name: 'RolesCreate',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      roleId: null,
      form: {
        name: '',
        isActive: false,
        permissions: [],
      },
      optionsPermissions: [],
    }
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchPermissions: 'roles/fetchPermissions',
      fetchRoleById: 'roles/fetchRoleById',
      createRole: 'roles/createRole',
      updateRole: 'roles/updateRole',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)
      this.getPermissions()

      if (!this.$route.params.id) return

      this.roleId = this.$route.params.id
      this.getRoleById(this.roleId)
    },

    create() {
      this.waitRequest(() => {
        return this.createRole(this.form)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Role is added',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },

    update() {
      this.waitRequest(() => {
        return this.updateRole({ id: this.roleId, form: this.form })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Role is updated',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },

    getPermissions() {
      this.fetchPermissions()
        .then(roles => {
          this.optionsPermissions = Object.entries(roles).map(([key, value]) => ({ label: key, value }))
        })
        .catch(this.checkErrors)
    },

    getRoleById(roleId) {
      this.fetchRoleById(roleId)
        .then(response => {
          const { name, isActive, permissions } = response.data
          this.form.name = name
          this.form.isActive = isActive
          this.form.permissions = permissions
        })
        .catch(this.checkErrors)
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      if (this.roleId) return this.update()

      this.create()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
